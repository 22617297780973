.homecontainerFirstLoginPage {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}
.bdsmdsFirstLoginPageNavbar {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  background: rgba(5, 11, 38, 1);
  overflow: auto;
}
.conmmon-navbar-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}
