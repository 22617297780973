* {
  box-sizing: border-box;
}
body {
  font-family: "Manrope", sans-serif !important;
}
/* In your CSS file or inline styles */
a {
  color: inherit; /* Use the color inherited from the parent */
  text-decoration: none; /* Remove underline */
}
.errorText {
  color: #ff0000;
  font-size: 12px;
  font-weight: 500;
}
body {
  margin: 0;
}
.godenty-container {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  position: relative;
}
.top-section-background {
  background-image: url("./Assets/TopBgImage.png");
  background-size: cover;
  margin-top: 60px;
  padding: 20px;
  /* margin-right: 0px; */
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 80%); */
  height: 912px;
  width: 100%;
}
.middle-section-background {
  background: rgba(255, 255, 255, 1);
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.middle-section-cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 100%;
  margin-bottom: 5%;
  background-image: url("./Assets/middleCardRightBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 45px;
}
.middle-section-cards-second {
  background-image: url("./Assets/middleCardLeftbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.godenty-footer-container {
  background-image: url("./Assets/footerBg.png");
  background-size: cover;
  height: 455px;
  width: 100%;
  padding: 20px;
}
.footer-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer-text {
  height: 60%;
  width: 30%;
}
.middle-section-cards h3 {
  font-weight: 700;
  color: rgba(24, 46, 75, 1);
}
.middle-section-cards p {
  color: rgba(158, 158, 158, 1);
  font-weight: 500;
}
/* .middle-section-cards img {
  height: 60%;
  width: 80%;
} */
.godenty-navbar {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  /* background: rgba(5, 11, 38, 1); */
  background: white;
  overflow: auto;
}
.godenty-navbar-links {
  display: flex;
  align-items: center;
  overflow: auto;
  gap: 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  color: rgba(255, 255, 255, 1);
  list-style: none;
}
.godenty-navbar-links li {
  cursor: pointer;
}
.signup-button {
  width: 92px;
  /* height: 41px; */
  border-radius: 25px;
  border: solid 1px rgba(41, 39, 53, 1);
  /* background-color: rgb(41, 39, 53, 1); */
  background: linear-gradient(192.22deg, #060c27 -0.14%, #05103e 96.63%);
  padding: 10px;
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  cursor: pointer;
}
.active-tab-link {
  color: rgba(216, 120, 56, 1);
  text-decoration: underline;
  text-underline-offset: 4px;
}
.headings-h1 {
  color: white;
  font-size: 50px;
  font-family: Manrope;
  font-weight: 700;
  font-family: Manrope;
  line-height: 84px;
  letter-spacing: 0em;
}
.span-color {
  color: rgba(255, 134, 96, 1);
}
.rounded-labels {
  display: flex;
  align-items: center;
  width: 20%;
  border: 21px solid rgba(255, 134, 96, 1);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
}
.rounded-labels img {
  width: 80%;
  display: block;
  margin: 0 auto;
  margin-bottom: 2px;
}
.top-section-card {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}
.footer-signUp {
  width: 316.5px;
  height: 67px;
}
.about-vissionMissionContainer {
  padding: 7%;
  display: flex;
  flex-direction: column;
}

.about-vissionMissionCard {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10%;
}
.aboutSubvissionMissionCard {
  width: 50%;
}
.aboutSubvissionMissionCard p {
  color: rgba(158, 158, 158, 1);
  font-family: Satoshi Variable;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
}
.ourMissionContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10%;
}
.ourMissionContainer img {
  width: 45%;
}
.about-vissionMissionCard img {
  width: 50%;
}
.ourvissionspancolor {
  font-family: Manrope;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
}
.ourvissionspanyellowcolor {
  color: rgba(218, 120, 56, 1);
  font-family: Manrope;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
}
.about-vissionMissionCard p {
  /* width: 40%; */
  color: rgba(158, 158, 158, 1);
  font-family: Satoshi Variable;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
}
.about-paragraph {
  color: rgba(158, 158, 158, 1);
  font-family: Satoshi Variable;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  width: 60%;
}
.vissionCardImages {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
}
.smallRectangleBg {
  box-shadow: 1.1292399168014526px 10.659788131713867px 58.599578857421875px 0px
    rgba(96, 96, 96, 0.08);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 264px;
  transform: rotate(15deg);
}
.visionDoctorImg-position {
  position: absolute;
  /* right: 50%; */
  left: 52%;
  top: 30%;
}
.vissionSmallImg-position {
  position: absolute;
  right: 50%;
}
.visionStetothScopeImg-position {
  position: absolute;
  bottom: 0;
  right: 29%;
  z-index: 2;
}
.visionTeethImg-position {
  position: absolute;
  bottom: -319px;
  left: 230px;
  z-index: 2;
  background: #ffffff;
  transform: rotate(23deg);
}
.responsiveMargin {
  margin-bottom: 15%;
}

.muchmoreListitem {
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 15px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1%;
  height: 63px;
  color: rgba(190, 190, 190, 1);
  font-family: Satoshi Variable;
  font-size: 26px;
  font-weight: 500;
}
.opacityImage1 {
  opacity: 0.5;
  position: absolute;
  right: 250px;
  bottom: 186px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.opacityImage2 {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 44px;
}
.middleLoginBg img {
  width: 90%;
  height: 50%;
}
.missionLoveimg {
  transform: rotate(-20deg);
}
.goDentyImagBG {
  transform: rotate(17deg);
  background-color: #ffffff;
  margin-bottom: -20%;
  position: absolute;
  left: 480px;
}
/* working */
.middle-section-background-desktop {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: unset;
  /* position: relative; */
  position: absolute;
  top: -133px;
  margin-bottom: -10%;
}
.SignUpPage-leftSidecontainer {
  background: linear-gradient(192.22deg, #060c27 -0.14%, #05103e 96.63%);
  width: 30%;
  padding: 2%;
  display: flex;
  flex-direction: column;
}
.signUp-formcontainer {
  width: 60%;
  margin: auto;
  box-shadow: 1.1292399168014526px 10.659788131713867px 58.599578857421875px 0px
    rgba(96, 96, 96, 0.08);
  border-radius: 10px;
  padding: 20px;
}
.signUp-formcontainer-input {
  width: 100%;
  box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  border: none;
  padding: 1rem;
  margin-bottom: 15px;
  outline: none;
}
.signUp-formcontainer ::placeholder {
  color: rgba(165, 165, 165, 1);
}
.clinician-lastcard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url("./Assets/middleCardRightBg.png");
  /* background-position: bottom; */
  width: 100%;
}
.clinician-lastcard img {
  height: 540px;
}

.getCurrentImageClass {
  height: 313px;
}
.roundedcontainer {
  display: flex;
  align-items: center;
  width: 180px;
  height: 189px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  opacity: 0.5;
}
.roundedcontainerOpacityimage {
  width: 100%;
  height: 100%;
}
.navbar-opacityImgContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 5%;
}
.right-space {
  margin-left: -200px;
  margin-top: 50px;
}
.clinicianLastcontainerImage {
  height: 554px;
  display: flex;
  align-items: center;
}
.display-smallscreen-middle {
  display: none;
}
.goLogImg {
  width: 80px;
  height: 50.26px;
  margin-left: 45px;
}
.body-headings {
  font-family: Satoshi Variable;
  font-size: 60px;
  font-weight: 500;
  line-height: 86px;
  letter-spacing: 0em;
}
.aboutus-subcontainer {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.aboutUslogo {
  width: 227px;
  height: 138px;
  transform: rotate(-20deg);
}
/* .footer-text {
  font-family: Manrope;
  font-size: 60px;
  font-weight: 700;
} */
.footr-texthighligtblue {
  color: rgba(46, 88, 144, 1);
  font-family: Manrope;
  font-size: 60px;
  font-weight: 700;
}
.footer-textnormalblue {
  color: rgba(113, 141, 180, 1);
  font-family: Manrope;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 0px !important;
}
.middle-yellowcontainer {
  background-color: rgba(255, 236, 224, 1);
  width: 100%;
  height: 475px;
  padding: 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.middle-yellowcontainer h2 {
  font-family: Manrope;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 0em;
}
.middle-yellowcontainer span {
  color: rgba(218, 120, 56, 1);
}
.middle-yellowcontainer img {
  width: 310.5px;
  height: 67px;
}
/* reuseble-card */
.commoncard-container {
  width: 561px;
  height: 860px;
}
.muchmore-heading {
  font-family: Satoshi Variable;
  font-size: 60px;
  font-weight: 500;
  line-height: 84px;
  letter-spacing: 0em;
  padding: 5%;
}
.position-relative {
  position: relative;
}
.small-circle {
  width: 45px; /* Adjust size as needed */
  height: 45px; /* Adjust size as needed */
  background-color: white; /* Example background color */
  border-radius: 50%; /* Makes the div circular */
  color: gray; /* Text color */
  font-size: 20px; /* Adjust font size as needed */
  text-align: center; /* Center the text horizontally */
  line-height: 45px; /* Center the text vertically */
  border: 2px solid #d3d3d3;
  font-weight: 620;
  position: absolute;
  right: 3%;
  top: 5%;
}
.image-contain {
  object-fit: contain;
  width: 100%;
  height: 66%;
}
.play-btn-left {
  position: absolute;
  right: 6%;
  top: 37%;
}
.play-btn-right {
  position: absolute;
  left: 6%;
  top: 37%;
}
.top-section-position {
  position: relative;
}

.opacity {
  opacity: 1;
  transition: opacity 3s ease-in-out;
  visibility: visible;
}

.no-opacity {
  opacity: 0;
  transition: opacity 3s ease-in-out;
  visibility: hidden;
}

.containerHeight {
  position: relative;
  height: 180vh;
}
.label-designation {
  color: "rgba(165, 165, 165, 1)";
  cursor: pointer;
}

@media only screen and (min-width: 200px) {
  .containerHeight {
    height: 280vh;
  }
}

@media only screen and (min-width: 280px) {
  .containerHeight {
    height: 370vh;
  }
}

@media only screen and (min-width: 300px) {
  .containerHeight {
    height: 390vh;
  }
}
@media only screen and (min-width: 325px) {
  .containerHeight {
    height: 400vh;
  }
}
@media only screen and (min-width: 353px) {
  .containerHeight {
    height: 420vh;
  }
}
@media only screen and (min-width: 375px) {
  .containerHeight {
    height: 440vh;
  }
}

@media only screen and (min-width: 400px) {
  .containerHeight {
    height: 450vh;
  }
}

@media only screen and (min-width: 430px) {
  .containerHeight {
    height: 470vh;
  }
}

@media only screen and (min-width: 460px) {
  .containerHeight {
    height: 490vh;
  }
}

@media only screen and (min-width: 482px) {
  .containerHeight {
    height: 510vh;
  }
}

@media only screen and (min-width: 520px) {
  .containerHeight {
    height: 520vh;
  }
}

@media only screen and (min-width: 550px) {
  .containerHeight {
    height: 540vh;
  }
}

@media only screen and (min-width: 570px) {
  .containerHeight {
    height: 570vh;
  }
}

@media only screen and (min-width: 600px) {
  .containerHeight {
    height: 200vh;
  }
}

@media only screen and (min-width: 1000px) {
  .containerHeight {
    height: 190vh;
  }
}
.muchmore-marginright {
  margin-left: -85px;
}
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .play-btn-left {
    position: absolute;
    right: 5%;
    top: 45%;
  }
  .play-btn-right {
    position: absolute;
    left: 5%;
    top: 45%;
  }
}
.signup-login-btn {
  width: 310.5px;
  height: 67px;
  border-radius: 30px;
  background-color: rgba(218, 120, 56, 1);
  color: white;
  border: none;
  font-family: Satoshi Variable;
  font-size: 22px;
  font-weight: 700;
  line-height: 31px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .signup-login-btn {
    width: 298px;
    height: 62px;
    font-size: 18px;
  }
  .image-contain-mobile {
    width: 90%;
  }
  .top-section-background {
    width: 100%;
    height: 548px;
  }
  .headings-h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .rounded-labels {
    width: 50%;
    border-width: 10px;
  }
  .middle-section-cards {
    margin: 5%;
  }
  .top-section-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .middle-section-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -83px;
    background: none;
  }
  .middle-section-cards {
    width: 100%;
    border-radius: 25px;
  }
  /* .middle-section-cards img {
    width: 100%;
  } */
  .footer-text {
    height: 50%;
    width: 50%;
  }
  /* .footer-signUp {
    height: 22%;
    width: 35%;
  } */
  .about-vissionMissionCard {
    flex-direction: column;
    justify-content: center;
  }
  .about-vissionMissionCard img {
    width: 100%;
  }
  .aboutSubvissionMissionCard p {
    width: 100%;
    font-family: Satoshi Variable;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .vissionCardImages {
    margin-bottom: 20%;
  }
  .godenty-footer-container {
    height: 405px;
    width: 100%;
  }
  .footer-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-signUp {
    width: 298px;
    height: 62px;
  }
  .footer-text {
    width: 90%;
    height: 50%;
  }
  .responsiveMargin {
    margin-top: 50%;
  }
  .responsiveMarginTeeth {
    margin-bottom: 100%;
  }
  .visionTeethImg-position {
    left: 3px;
    bottom: -210px;
  }
  .middleLoginBg {
    height: 25%;
    align-items: center;
  }
  .middleLoginBg img {
    height: 80%;
    width: 100%;
  }
  .goDentyImagBG {
    transform: rotate(17deg);
    position: relative;
    top: -90px;
    right: -93px;
    background-color: #ffffff;
    margin-bottom: -20%;
    left: unset;
  }
  .SignUpPage-leftSidecontainer {
    display: none;
  }
  .signUp-formcontainer {
    width: 80%;
  }
  .clinician-lastcard {
    display: flex;
    flex-direction: column;
  }
  .clinician-lastcard img {
    height: 100%;
  }
  .getCurrentImageClass {
    height: 120px;
    margin-left: 50px;
  }
  .roundedcontainer {
    width: 39.75px;
    height: 39.75px;
  }
  .navbar-opacityImgContainer {
    flex-direction: row;
    gap: 130px;
    margin-top: -50px;
  }
  .right-space {
    margin-left: 0px;
    margin-top: 0px;
  }
  .clinicianLastcontainerImage {
    display: none;
  }
  .goLogImg {
    width: 66px;
    height: 43.26px;
    margin-left: 10px;
  }
  .body-headings {
    font-family: Satoshi Variable;
    font-size: 32px;
    font-weight: 500;
    line-height: 46px;
    letter-spacing: 0em;
  }
  .aboutSubvissionMissionCard {
    width: 100%;
  }
  .about-paragraph {
    width: 100%;
    font-family: Satoshi Variable;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 22px !important;
    letter-spacing: 0em;
  }
  .aboutus-subcontainer {
    width: 100%;
  }
  .smallRectangleBg {
    width: 70%;
    width: 174.19px;
    height: 174.06px;
    transform: rotate(-15.02 deg);
    margin-bottom: 10px;
    box-shadow: 1.1292399168014526px 10.659788131713867px 58.599578857421875px
      0px rgba(96, 96, 96, 0.08);
  }
  .aboutUslogo {
    width: 149.41px;
    height: 90.83px;
    transform: rotate(-18deg);
  }
  .footr-texthighligtblue {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }
  .footer-textnormalblue {
    font-family: Satoshi Variable;
    font-size: 32px;
    margin-bottom: 30px !important;
  }
  .footer-textcommon {
    width: 80%;
  }
  .middle-yellowcontainer {
    height: 409px;
  }
  .middle-yellowcontainer h2 {
    font-size: 32px;
    font-weight: 700;
  }
  .middle-yellowcontainer img {
    width: 298px;
    height: 62px;
  }
  .commoncard-container {
    width: 100%;
    height: 489px;
  }
  .muchmore-heading {
    font-size: 32px;
    font-weight: 500;
    line-height: 46px;
    letter-spacing: 0em;
  }
  .muchmoreListitem {
    height: 53px;
    font-family: Satoshi Variable;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    border-radius: 25px;
  }
  .label-designation {
    display: block;
  }
}

@media screen and (min-width: 600px) {
  .noDisplay-bigScreen {
    display: none;
  }
  .card-height {
    height: 45rem;
  }
}
